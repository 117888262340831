import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { Grid, TextField, Typography, InputLabel, Link } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { CiImageOn } from "react-icons/ci";
import { FaRegAddressCard } from "react-icons/fa";
import { GrCertificate } from "react-icons/gr";
import { MdModeEditOutline, MdDelete } from "react-icons/md";
import { FiPlus } from "react-icons/fi";

export default function MerchantDocument(props) {
    const { state, inputErrors, handleChange, logoImage, handleFilesDelete } = props;
    const { t } = useTranslation();

    const theme = useTheme();
    const styles = useStyles();

    const UploadButton = ({ icon }) => {
        let IconDisplay = null;
        let IconText = '';
        let IconFileDisplay = '';
        let helperText = '';
        switch (icon) {
            case 'logo':
                IconDisplay = <CiImageOn className={styles.uploadButtonIconDesign} />;
                IconText = t('merchant.uploadLogo');
                IconFileDisplay = 'newLogo';
                helperText = 'new_logo';
                break;
            case 'ic':
                IconDisplay = <FaRegAddressCard className={styles.uploadButtonIconDesign} />;
                IconText = t('merchant.uploadIc');
                IconFileDisplay = 'ic';
                helperText = 'ic';
                break;
            case 'document':
                IconDisplay = <GrCertificate className={styles.uploadButtonIconDesign} />;
                IconText = t('merchant.uploadBusiness');
                IconFileDisplay = 'supportDoc';
                helperText = 'support_doc';
                break;
            default: 
                break;
        }

        return (
            <div className={styles.uploadButtonDesign} style={{ position: 'relative' }}>
                <InputLabel htmlFor={IconFileDisplay} style={{ cursor: 'pointer', background: '#EEE', padding: 25, borderRadius: 15, whiteSpace: 'inherit', lineHeight: 1.1 }}>
                    {
                        icon === 'logo' && (logoImage || state?.newDocs?.newLogo || state?.existDocs?.existLogo)
                            ?
                            <div className={styles.imagePreviewDisplayDesign} style={{ backgroundImage: `url(${logoImage || state?.newDocs?.newLogo?.file_name || state?.existDocs?.existLogo?.file_name})`, boxShadow: '2px 2px 4px 0px #00000040 inset', objectFit: 'cover' }}>
                                <div htmlFor={IconFileDisplay} className={styles.editButtonDesign}>
                                    <MdModeEditOutline style={{ color: theme.palette.white.main, fontSize: 20, }} />
                                </div>
                            </div>
                            : icon === 'ic' && (_.size(state?.newDocs?.newIcs) > 0 || _.size(state?.existDocs?.existIcs) > 0)
                                ?
                                <div className={styles.imagePreviewDisplayDesign} style={{ backgroundImage: `url(images/merchants/ic_icon.png)`, objectFit: 'contain' }}>
                                    <div htmlFor={IconFileDisplay} className={styles.editButtonDesign}>
                                        <FiPlus style={{ color: theme.palette.white.main, fontSize: 20, }} />
                                    </div>
                                </div>
                                : icon === 'document' && (_.size(state?.newDocs?.newSupportDocs) > 0 || _.size(state?.existDocs?.existSupportDocs) > 0)
                                    ?
                                    <div className={styles.imagePreviewDisplayDesign} style={{ backgroundImage: `url(images/merchants/pdf_icon.png)`, objectFit: 'contain' }}>
                                        <div htmlFor={IconFileDisplay} className={styles.editButtonDesign}>
                                            <FiPlus style={{ color: theme.palette.white.main, fontSize: 20, }} />
                                        </div>
                                    </div>
                                    :
                                    <>
                                        {IconDisplay}
                                        <Typography style={{ color: theme.palette.darkGray.main }}>{IconText}</Typography>
                                    </>
                    }
                </InputLabel>

                <TextField
                    type={"file"}
                    sx={{ display: "none" }}
                    id={IconFileDisplay}
                    name={IconFileDisplay}
                    onChange={handleChange}
                    helperText={inputErrors && inputErrors[helperText] ? inputErrors[helperText] : ''}
                    error={inputErrors && inputErrors[helperText] ? true : false}
                    inputProps={{
                        multiple: icon !== 'logo' ? true : false
                    }}
                />
            </div>
        );
    }

    return (
        <>
            <Typography variant='body1' style={{ textAlign: 'left', color: theme.palette.gray.main, margin: "-5px 0 15px 0" }}>{t('merchant.uploadNoted')}</Typography>

            {
                _.size(inputErrors) > 0 &&
                <div style={{ margin: "-5px 0 15px 0" }}>
                    {
                        inputErrors?.new_logo &&
                        <Typography variant='body1' style={{ textAlign: 'left', color: theme.palette.primary.secondary }}>{inputErrors?.new_logo}</Typography>
                    }
                    {
                        inputErrors?.ic_front &&
                        <Typography variant='body1' style={{ textAlign: 'left', color: theme.palette.primary.secondary }}>{inputErrors?.ic_front}</Typography>
                    }
                    {
                        inputErrors?.ic_back &&
                        <Typography variant='body1' style={{ textAlign: 'left', color: theme.palette.primary.secondary }}>{inputErrors?.ic_back}</Typography>
                    }
                    {
                        _.map(inputErrors, (error, key) => {
                            if (_.split(key, '.')[0] === 'support_doc') {
                                return (
                                    <Typography key={key} variant='body1' style={{ textAlign: 'left', color: theme.palette.primary.secondary }}>
                                        {error}
                                    </Typography>
                                )
                            }
                        })
                    }
                </div>
            }

            <Grid container spacing={4} style={{ justifyContent: 'space-around' }}>
                <Grid item xs={12} lg={4} style={{ maxWidth: 250 }}>
                    <UploadButton icon='logo' />
                    <div className='m-tb-10 m-r-10'>
                        {
                            logoImage || state?.newDocs?.newLogo || state?.existDocs?.existLogo
                                ?
                                <div className='m-tb-10 txt-left' style={{ display: 'flex', justifyContent: 'space-between', wordBreak: 'break-all' }}>
                                    <p style={{ color: theme.palette.darkGray.main, width: '80%' }}>{state?.newDocs?.newLogo?.[0]?.name || state?.existDocs?.existLogo?.descr || t('button.loading')}</p>
                                    <MdDelete style={{ fontSize: 30, color: theme.palette.primary.main, cursor: 'pointer' }} onClick={() => handleFilesDelete('newLogo', null)} />
                                </div>
                                : <Typography className='txt-left' style={{ color: theme.palette.darkGray.main }}>{t('merchant.imageSize', { width: '350', height: '350' })}</Typography>
                        }
                    </div>
                </Grid>

                <Grid item xs={12} lg={4} style={{ maxWidth: 250 }}>
                    <UploadButton icon='ic' />
                    <div className='m-tb-10 m-r-10' style={{ height: (_.size(state?.newDocs?.newIcs) > 0 || _.size(state?.existDocs?.existIcs) > 0) ? 150 : '100%', overflowY: 'scroll' }}>
                        {
                            _.size(state?.newDocs?.newIcs) > 0 || _.size(state?.existDocs?.existIcs) > 0
                                ? <>
                                    {
                                        state?.newDocs?.newIcFront &&
                                        <div className='m-tb-10 txt-left' style={{ display: 'flex', justifyContent: 'space-between', wordBreak: 'break-all' }}>
                                            <Link target="_blank" href={state?.newDocs?.newIcFront?.name} underline='none' style={{ width: '80%' }} >
                                                <p style={{ fontWeight: 'bold', color: theme.palette.darkGray.main, width: '80%' }}>{state?.newDocs?.newIcFront?.name} </p>
                                            </Link>
                                            <MdDelete style={{ fontSize: 30, color: theme.palette.primary.main, cursor: 'pointer' }} onClick={() => handleFilesDelete('icFront', null)} />
                                        </div>
                                    }
                                    {
                                        state?.newDocs?.newIcBack &&
                                        <div className='m-tb-10 txt-left' style={{ display: 'flex', justifyContent: 'space-between', wordBreak: 'break-all' }}>
                                            <Link target="_blank" href={state?.newDocs?.newIcBack?.name} underline='none' style={{ width: '80%' }} >
                                                <p style={{ fontWeight: 'bold', color: theme.palette.darkGray.main }}>{state?.newDocs?.newIcBack?.name} </p>
                                            </Link>
                                            <MdDelete style={{ fontSize: 30, color: theme.palette.primary.main, cursor: 'pointer' }} onClick={() => handleFilesDelete('icBack', null)} />
                                        </div>
                                    }
                                    {
                                        state?.existDocs?.existIcFront &&
                                        <div className='m-tb-10 txt-left' style={{ display: 'flex', justifyContent: 'space-between', wordBreak: 'break-all' }}>
                                            <Link target="_blank" href={state?.existDocs?.existIcFront?.file_name} underline='none' style={{ width: '80%' }} >
                                                <p style={{ color: theme.palette.darkGray.main, width: '80%' }}>{state?.existDocs?.existIcFront?.descr} </p>
                                            </Link>
                                            <MdDelete style={{ fontSize: 30, color: theme.palette.primary.main, cursor: 'pointer' }} onClick={() => handleFilesDelete('icFront', null)} />
                                        </div>
                                    }
                                    {
                                        state?.existDocs?.existIcBack &&
                                        <div className='m-tb-10 txt-left' style={{ display: 'flex', justifyContent: 'space-between', wordBreak: 'break-all' }}>
                                            <Link target="_blank" href={state?.existDocs?.existIcBack?.file_name} underline='none' style={{ width: '80%' }} >
                                                <p style={{ color: theme.palette.darkGray.main }}>{state?.existDocs?.existIcBack?.descr} </p>
                                            </Link>
                                            <MdDelete style={{ fontSize: 30, color: theme.palette.primary.main, cursor: 'pointer' }} onClick={() => handleFilesDelete('icBack', null)} />
                                        </div>
                                    }
                                </>
                                : <Typography className='txt-left' style={{ color: theme.palette.darkGray.main }}>{t('merchant.icRequirement')}</Typography>
                        }
                    </div>
                </Grid>

                <Grid item xs={12} lg={4} style={{ maxWidth: 250 }}>
                    <UploadButton icon='document' />
                    <div className='scrollable-container m-tb-10 m-r-10' style={{ height: _.size(state?.newDocs?.newSupportDocs) > 0 || _.size(state?.existDocs?.existSupportDocs) > 0 ? 150 : '100%', overflowY: 'scroll' }}>
                        {
                            _.size(state?.newDocs?.newSupportDocs) > 0 || _.size(state?.existDocs?.existSupportDocs) > 0
                                ?
                                <>
                                    {
                                        _.size(state?.newDocs?.newSupportDocs) > 0 &&
                                        _.map(state?.newDocs?.newSupportDocs, (doc, idx) => {
                                            return (
                                                <div className='m-tb-10 txt-left' key={idx} style={{ display: 'flex', justifyContent: 'space-between', wordBreak: 'break-all' }}>
                                                    <Link target="_blank" href={doc?.file_name} underline='none' style={{ width: '80%' }} >
                                                        <p style={{ fontWeight: 'bold', color: theme.palette.darkGray.main }}>{doc?.name || doc?.descr}</p>
                                                    </Link>
                                                    <MdDelete style={{ fontSize: 30, color: theme.palette.primary.main, cursor: 'pointer' }} onClick={() => handleFilesDelete('supportDoc', idx)} />
                                                </div>
                                            )
                                        })
                                    }
                                    {
                                        _.size(state?.existDocs?.existSupportDocs) > 0 && 
                                        _.map(state?.existDocs?.existSupportDocs, (doc, idx) => (
                                            <div className='m-tb-10 txt-left' key={idx} style={{ display: 'flex', justifyContent: 'space-between', wordBreak: 'break-all' }}>
                                                <Link target="_blank" href={doc?.file_name} underline='none' style={{ width: '80%' }} >
                                                    <p style={{ color: theme.palette.darkGray.main }}>{doc?.name || doc?.descr}</p>
                                                </Link>
                                                <MdDelete style={{ fontSize: 30, color: theme.palette.primary.main, cursor: 'pointer' }} onClick={() => handleFilesDelete('supportDoc', idx)} />
                                            </div>
                                        ))
                                    }
                                </>
                                : <Typography className='txt-left' style={{ color: theme.palette.darkGray.main }}>{t('merchant.fileTypes')}</Typography>
                        }
                    </div>
                </Grid>
            </Grid>
        </>
    )
}

const useStyles = makeStyles(theme => ({
    uploadButtonDesign: {
        width: '100%',
        aspectRatio: 1/1,
        alignContent: 'center',
        boxShadow: '-3px -3px 4px 0px #FFFFFF, 3px 3px 5px 0px #0000001A',
        borderRadius: 10,
    },
    uploadButtonIconDesign: {
        fontSize: 100,
        color: theme.palette.darkGray.main,
    },
    editButtonDesign: {
        position: 'absolute',
        right: 5,
        bottom: 5,
        borderRadius: '50%',
        aspectRatio: 1/1,
        height: 35,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.main,
        boxShadow: '2px 2px 4px 0px #FFFFFF36 inset, -2px -2px 4px 0px #00000021 inset, 4px 4px 8px 0px #00000026',
    },
    imagePreviewDisplayDesign: {
        width: '100%',
        aspectRatio: 1/1,
        objectPosition: 'center',
        overflow: 'hidden',
        borderRadius: 5,
        backgroundSize: 'auto 100%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    },
}));