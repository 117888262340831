import _ from 'lodash';
import React, { useState , useEffect } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import { getUrl , postUrl } from '@utils/ApiAction';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Button, TextField, FormHelperText, IconButton, InputAdornment, Dialog, DialogContent , FormControl , RadioGroup , FormControlLabel , Radio } from '@mui/material';

import { Link as RouterLink, useNavigate} from 'react-router-dom';

import { FiEye, FiEyeOff } from "react-icons/fi";
import { currencyFormat } from '@utils/Tools';

// import CustomSelect from '@components/CustomSelect';
import useNotificationLoading from '@utils/useNotificationLoading';
import TwoFA from '@components/TwoFA';

const INITIAL_STATE = {
    amount: "",
    remark: "",
    type: "unit",
    authenticationCode: "",
    securityPassword: "",
}

const INITIAL_AMOUNT = {
    valueAmount: 0,
    valueFee: 0,
    valueTotal: 0,
    unitAmount: 0,
    unitFee: 0,
    unitTotal: 0,
    amount: 0,
    fee: 0,
    total: 0,
}

const TradeMerchantWithdraw = () => {
    const styles = useStyles();
    const theme = useTheme();
    let navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    // const [searchParams] = useSearchParams();

    const [inputErrors, setInputErrors] = useState();
    const [state, setState] = useState(INITIAL_STATE);
    const [withdrawalsAmount, setWithdrawalsAmount] = useState(INITIAL_AMOUNT);
    const [currentPrice, setCurrentPrice] = useState(0);
    const [merchantProfile, setMerchantProfile] = useState([]);
    const [conditionError, setConditionError] = useState([]);

    const [twoFASetting, setTwoFASetting] = useState(false);
    const [submitAction, setSubmitAction] = useState(true);
    const [open, setOpen] = useState(false);
    const [dialogBoxOpen, setDialogBoxOpen] = useState(false);
    const [showPassword, setShowPassword] = useState({
        security_password: false,
    });
    const [viewDetails, setViewDetails] = useState(false);
    const [viewSummary, setViewSummary] = useState(false);
    const [preview, setPreview] = useState(false);

    // calculation backend data
    const [withdrawAmountPercent, setWithdrawAmountPercent] = useState(0);
    const [withdrawServiceFee, setWithdrawServiceFee] = useState(0);
    const [withdrawServiceFeeMethod, setWithdrawServiceFeeMethod] = useState('fixed');
    const [withdrawFeeMethod, setWithdrawFeeMethod] = useState('addition');
    // allow withdraw
    const [allowWithdraw, setAllowWithdraw] = useState(true);

    useEffect(() => {
        totalCal();

        // eslint-disable-next-line
    }, [state?.amount, withdrawServiceFee, state?.type]);

    useEffect(() => {
        setLoading(true);
        callApi();

        // eslint-disable-next-line
    }, []);

    const callApi = () => {
        getUrl('/merchant-withdraws-request-details' ).then(response => {
            if(response.status)
            {
                setMerchantProfile(response.user_merchant);

                setTwoFASetting(response.twoFA);

                setSubmitAction(_.size(response?.err_msg) > 0 ? true : false);
                setConditionError(response?.err_msg);

                setWithdrawAmountPercent(response?.current_param?.withdraw_percent);

                setWithdrawFeeMethod(response?.current_param?.fee_type);

                setWithdrawServiceFee(response?.current_param?.service_fee_number);
                setWithdrawServiceFeeMethod(response?.current_param?.service_fee_method);

                setCurrentPrice(parseFloat(response?.current_price));

                setAllowWithdraw(response?.current_param?.withdraw_function);
            }
            else
            {
                navigate(`/`);
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        }).finally (() => {
            setLoading(false)
        })
        ;
    }

    const handleChange = ({ target }) => {
        let { name, value } = target;
        if (name === 'amount') {
            if (state.type === 'unit')
            {
                value = /^\d+$/.test(value) && !isNaN(value) ? value : ""; 
            }
            else
            {
                value = !/^\s*$/.test(value) && !isNaN(value) ? value : "";
            }
            setState({ ...state, [name]: value });
        }
        else if (name === 'type'){
            setState({ ...state, [name]: value , amount: 0});
        }
        else{
            setState({ ...state, [name]: value });
        }
    };

    const handleClickShowPassword = (value) => {
        setShowPassword({
            ...showPassword,
            security_password: !showPassword.security_password
        });
    };

    function totalCal()
    {
        let currentInputAmount = parseFloat(state?.amount > 0 ? state?.amount : 0);
        let currentType = state?.type === 'unit';

        let calculationAmount = currentType 
            ? currentInputAmount * currentPrice 
            : currentInputAmount;

        let valueAmount = calculationAmount;
        let unitAmount = currentType 
            ? currentInputAmount 
            : currentInputAmount / currentPrice;

        let userWithdrawAmount = calculationAmount * withdrawAmountPercent / 100;

        let feeAmount = withdrawServiceFeeMethod === 'fixed'
            ? withdrawServiceFee
            : userWithdrawAmount * (withdrawServiceFee / 100);

        let valueFee = feeAmount;
        let unitFee = feeAmount / currentPrice;

        let valueTotal = valueAmount;
        let unitTotal = unitAmount;

        if ( withdrawFeeMethod === 'addition' )
        {
            valueTotal += valueFee;
            unitTotal += unitFee;
        }

        if ( withdrawFeeMethod === 'reduction' ) 
        {
            userWithdrawAmount -= feeAmount;
        }

        setWithdrawalsAmount({
            ...withdrawalsAmount, 

            valueAmount: valueAmount,
            valueFee: valueFee,
            valueTotal: valueTotal,

            unitAmount: (unitAmount),
            unitFee: (unitFee),
            unitTotal: Math.ceil(unitTotal),

            amount: valueAmount,
            fee: feeAmount,
            total: userWithdrawAmount,
        });
    }
    
    const handleOpenDrawer = () => {
        if ( state?.amount !== '' && state?.securityPassword ) {
            if (twoFASetting)
            {
                setOpen(true);
            }
            else
            {
                setDialogBoxOpen(true);
            }
        } else {
            addAlert('', t('error.requiredFields', {'value': t(`user.securityPassword`) + " ," + t(`transfer.amount`) } ), 'error', '');
        }
    }
    
    const nextFunction = () => {
        return handleOpenDrawer ;
        // return twoFASetting ? handleOpenDrawer : submitData ;
    }

    const submitData = () => {
        
        setLoading(true);
        setInputErrors();

        let apiData = {
            amount: state.amount,
            remark: state.remark,
            type: state.type,

            authentication_code: state.authenticationCode,
            security_password: state.securityPassword,

            fee_type: withdrawFeeMethod,
            service_fee_method: withdrawServiceFeeMethod,
            service_fee_number: withdrawServiceFee,
            withdraw_percent: withdrawAmountPercent,
        };

        postUrl('/member-request-merchant-withdraws', apiData).then(response => {
            if (response.status) 
            {
                addAlert('', t('success.withdrawalsSuccess'), 'success', '');
                setOpen(false);
                setDialogBoxOpen(false);
                setState(INITIAL_STATE);
                setPreview(false);
                callApi();
                // navigate('/');
            }
            else 
            {
                setInputErrors(response.errors);
                setDialogBoxOpen(false);
                addAlert('', response.message || t('error.purchaseError'), 'error', '');
                let datas = ['remark', 'security_password', 'amount'];
                // version 1
                datas.some(element => {
                    if(response.errors && (Object.keys(response?.errors)).includes(element)){
                        setOpen(false);
                    }
                })

                // version 2
                // const errors = response?.errors;
                // if(!errors) return; 
                // const invalidFields = datas.filter(field => field in errors);
                // if(invalidFields.length > 0) {
                //     setOpen(false); 
                // }

            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        }).finally(() => {
            setLoading(false);
        })
        ;
    }

    const withdrawCondition = () => {

        if (withdrawFeeMethod === 'addition')
        {
            return (
                <ol className="withdraw-ol">
                    <li ><Typography variant='caption' style={{color: theme.palette.darkBase.main }}>test</Typography></li>
                </ol>
            )
        }

        if (withdrawFeeMethod === 'reduction')
        {
            return (
                <ol className="withdraw-ol">
                    <li ><Typography variant='caption' style={{color: theme.palette.darkBase.main }}>test</Typography></li>
                </ol>
            )
        }
    }
    
    const step1 = () => {
        let unitType = state.type === 'unit';
        let walletAmount = merchantProfile.merchant_profiles 
            ? (merchantProfile.merchant_profiles.withdrawable_value) 
            : 0;
        if (unitType) 
        {
            walletAmount = merchantProfile.merchant_profiles 
                ? (merchantProfile.merchant_profiles.passive_unit) 
                : 0;
        }
        let walletDisplay = currencyFormat(walletAmount, 2);
        let amount = viewDetails ? withdrawalsAmount.unitAmount : withdrawalsAmount.valueAmount;
        let fee = viewDetails ? withdrawalsAmount.unitFee : withdrawalsAmount.valueFee;
        let total = viewDetails ? withdrawalsAmount.unitTotal : withdrawalsAmount.valueTotal;

        return (
            <>
                <Typography variant='h6' className='txt-center p-b-30' style={{ fontWeight: 'bold', lineHeight: 1.1, color: theme.palette.darkBase.main }} >{t('title.merchatnWithdrawal')}</Typography>
                {
                    allowWithdraw ?
                        <>
                            <div
                                style={{
                                    backgroundColor: 'var(--Dark-Base, #2E3133)',
                                    padding: '40px 30px',
                                    textAlign: 'center',
                                    borderRadius: 15,
                                    margin: '10px 20px',
                                    boxShadow: '4px 4px 4px 0px rgba(0, 0, 0, 0.25) inset, 4px 4px 4px 0px rgba(255, 255, 255, 0.06)',
                                }}
                            >
                                <Typography style={{ fontSize: 10, }} >{t('general.walletBalance')}</Typography>
                                <Typography style={{ fontSize: 21, fontWeight: 700, }} >{walletDisplay}</Typography>
                                <Typography style={{ fontSize: 15, }} >{t('general.currentRate')}: ${currencyFormat(currentPrice, 3)}</Typography>
                            </div>

                            <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center" >

                                <Grid item xs={12}>
                                    <FormControl component="fieldset" style={{ marginLeft: 10 }}>
                                        <RadioGroup
                                            aria-label="type"
                                            name="type"
                                            value={state.type}
                                            onChange={handleChange}
                                            row
                                        >
                                            <FormControlLabel
                                                value="unit"
                                                control={<Radio />}
                                                style={{
                                                    margin: "0 20px 0 0"
                                                }}
                                                label={
                                                    <Typography style={{ color: theme.palette.darkBase.main }} >
                                                        {t('general.unit')}
                                                    </Typography>
                                                }
                                            />

                                            <FormControlLabel
                                                value="value"
                                                style={{
                                                    margin: "0 20px 0 0"
                                                }}
                                                control={<Radio />}
                                                label={
                                                    <Typography style={{ color: theme.palette.darkBase.main }} >
                                                        {t('general.value')}
                                                    </Typography>
                                                }
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        variant="standard"
                                        fullWidth
                                        name='amount'
                                        // label={t('withdraw.withdrawalAmount')}
                                        onChange={handleChange}
                                        helperText={inputErrors && inputErrors.amount ? inputErrors.amount : ''}
                                        error={inputErrors && inputErrors.amount ? true : false}
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{ disableUnderline: true }}
                                        value={state.amount || ''}
                                        placeholder={t('withdraw.withdrawalAmount')}
                                    />
                                    <FormHelperText>

                                    </FormHelperText>
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        variant="standard"
                                        fullWidth
                                        name='remark'
                                        // label={t('withdraw.remark')}
                                        onChange={handleChange}
                                        helperText={inputErrors && inputErrors.remark ? inputErrors.remark : ''}
                                        error={inputErrors && inputErrors.remark ? true : false}
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{ disableUnderline: true }}
                                        value={state.remark}
                                        placeholder={t('withdraw.remark')}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <div style={{ color: 'rgba(173, 173, 173, 1)' }}>{viewDetails ? t('general.convertValue') : t('general.convertUnit')}</div>
                                        <div
                                            onClick={() => viewDetails ? setViewDetails(false) : setViewDetails(true)}
                                            className='pointer'
                                            style={{
                                                height: 25,
                                                width: 43,
                                                borderRadius: 48,
                                                padding: 2,
                                                background: viewDetails ? 'var(--Menu, rgba(203, 32, 32, 1))' : 'rgba(240, 240, 240, 1)',
                                                boxShadow: '3px 3px 4px 0px rgba(255, 255, 255, 1), 3px 3px 2px 0px rgba(0, 0, 0, 0.1) inset',
                                                transition: 'all 0.5s'
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: 21,
                                                    height: 21,
                                                    borderRadius: 48,
                                                    background: viewDetails ? 'rgba(240, 240, 240, 1)' : 'var(--Menu, rgba(203, 32, 32, 1))',
                                                    transform: viewDetails ? 'translateX(17px)' : 'translateX(0)',
                                                    boxShadow: '2px 2px 4px 0px rgba(255, 255, 255, 0.21) inset, -2px -2px 4px 0px rgba(0, 0, 0, 0.13) inset, 4px 4px 8px 0px rgba(0, 0, 0, 0.15)',
                                                    transition: 'all 0.5s'
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div
                                        style={{
                                            margin: "10px 0",
                                        }}
                                    >
                                        <div style={{ display: 'flex', justifyContent: 'space-between', textAlign: "cneter", color: 'rgba(120, 120, 120, 1)' }}>
                                            <p>{t('withdraw.withdrawalAmount')}</p>
                                            <p>{!viewDetails ? '$' : null} {currencyFormat(amount, 2)} {viewDetails ? t('general.unit') : null}</p>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', textAlign: "cneter", color: 'rgba(120, 120, 120, 1)' }}>
                                            <p>{t('general.serviceFee')}</p>
                                            <p>{!viewDetails ? '$' : null} {currencyFormat(fee, 2)} {viewDetails ? t('general.unit') : null}</p>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', textAlign: "cneter", color: 'rgba(120, 120, 120, 1)' }}>
                                            <p style={{ fontWeight: 700 }}>{t('general.totalWithdrawalAmount')}</p>
                                            <p style={{ fontWeight: 700 }}>{!viewDetails ? '$' : null} {currencyFormat(total, 2)} {viewDetails ? t('general.unit') : null}</p>
                                        </div>
                                    </div>
                                </Grid>

                                <Grid item xs={12}>
                                    {
                                        _.size(conditionError) <= 0 ?
                                            <>
                                                {/* <Typography variant='body2' style={{color: theme.palette.darkBase.main }}>
                                        {t('withdraw.withdrawalCalculationMethod')}
                                    </Typography>
                                    { withdrawCondition() } */}
                                            </>
                                            :
                                            <>
                                                <Typography variant='body2' style={{ color: theme.palette.darkBase.main }}>
                                                    {t('withdraw.withDrawErrorConditionTitle')}
                                                </Typography>
                                                <ol className="withdraw-ol">
                                                    {
                                                        _.map(conditionError, (data, key) => {
                                                            return <li key={key}><Typography variant='caption' style={{ color: theme.palette.darkBase.main }}>{data}</Typography></li>;
                                                        })
                                                    }
                                                </ol>
                                            </>
                                    }
                                </Grid>

                            </Grid>

                            <Button
                                variant="contained"
                                fullWidth
                                className='big-button'
                                style={{ margin: '10px 0' }}
                                onClick={() => setPreview(true)}
                                disabled={state?.amount !== '' && state?.remark !== '' ? false : true}
                            >
                                {t('button.next')}
                            </Button>
                        </>
                        :
                        <div className='dashboard-w flex-c-m' style={{ minHeight: '70vh' }}>
                            <p className='txt-center fs-19' style={{ color: theme.palette.darkGray.main }}><b>{t('merchant.withdrawNotAvailable')}</b></p>
                        </div>
                }
              
            </>
        )
    }

    const step2 = () => {
        let unitType = state.type === 'unit';
        // let walletAmount = merchantProfile.merchant_profiles 
        //     ? (merchantProfile.merchant_profiles.redeemable_value) 
        //     : 0;
        // if (unitType) 
        // {
        //     walletAmount = merchantProfile.merchant_profiles 
        //         ? (merchantProfile.merchant_profiles.passive_unit) 
        //         : 0;
        // }
        // let walletDisplay = currencyFormat(walletAmount, 2);
        let amount = viewDetails ? withdrawalsAmount.unitAmount : withdrawalsAmount.valueAmount;
        let fee = viewDetails ? withdrawalsAmount.unitFee : withdrawalsAmount.valueFee;
        let total = viewDetails ? withdrawalsAmount.unitTotal : withdrawalsAmount.valueTotal;

        return (
            <>
                <Typography variant='h6' className='txt-center p-b-30' style={{ fontWeight: 'bold', lineHeight: 1.1, color: theme.palette.darkBase.main , padding: '0 0 10px'}} >{t('title.merchatnWithdrawalDetails')}</Typography>
               
                <div
                    style={{
                        padding: '0px 30px',
                        textAlign: 'center',
                        margin: '10px 20px',
                    }}
                >
                    <Typography style={{ fontSize:15, color: 'rgba(120, 120, 120, 1)' , lineHeight: '15px' }} >{t('withdraw.withdrawalAmount')}</Typography>
                    <Typography style={{ fontSize:25, fontWeight: 700, color: 'rgba(120, 120, 120, 1)' }} > { !unitType ? '$' : null } { unitType ? currencyFormat(withdrawalsAmount.unitAmount, 2) : currencyFormat(withdrawalsAmount.valueAmount, 2) } { unitType ? t('general.unit') : null }</Typography>
                    <Typography style={{ fontSize:15, color: 'rgba(120, 120, 120, 1)' , lineHeight: '15px' }} >≈ { unitType ? '$' : null } { unitType ? currencyFormat(withdrawalsAmount.valueAmount,2) : currencyFormat(Math.ceil(withdrawalsAmount.unitAmount), 2) } { !unitType ? t('general.unit') : null }</Typography>
                </div>

                <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center" >
                    
                    <Grid item xs={12}>
                        <div 
                            style={{
                                display: 'flex',
                                justifyContent: 'end',
                                alignItems: 'center',
                            }}
                        >
                            <div style={{ color: 'rgba(173, 173, 173, 1)', marginRight: 10}}>{ viewSummary ? t('general.convertValue') : t('general.convertUnit') }</div>
                            <div 
                                onClick={() => viewSummary ? setViewSummary(false) : setViewSummary(true)} 
                                className='pointer' 
                                style={{ 
                                    height: 25, 
                                    width: 43, 
                                    borderRadius: 48, 
                                    padding: 2, 
                                    background: viewSummary ? 'var(--Menu, rgba(203, 32, 32, 1))' : 'rgba(240, 240, 240, 1)', 
                                    boxShadow: '3px 3px 4px 0px rgba(255, 255, 255, 1), 3px 3px 2px 0px rgba(0, 0, 0, 0.1) inset', 
                                    transition:'all 0.5s'
                                }}
                            >
                                <div 
                                    style={{ 
                                        width: 21, 
                                        height: 21, 
                                        borderRadius: 48, 
                                        background: viewSummary ? 'rgba(240, 240, 240, 1)' : 'var(--Menu, rgba(203, 32, 32, 1))', 
                                        transform: viewSummary ? 'translateX(17px)' : 'translateX(0)', 
                                        boxShadow: '2px 2px 4px 0px rgba(255, 255, 255, 0.21) inset, -2px -2px 4px 0px rgba(0, 0, 0, 0.13) inset, 4px 4px 8px 0px rgba(0, 0, 0, 0.15)' ,
                                        transition:'all 0.5s'  
                                    }}
                                />
                            </div>
                        </div>
                        
                        <div 
                            style={{
                                margin: "10px 0",
                                borderRadius: 20,
                                padding: 20,
                                boxShadow: '3px 3px 4px 0px rgba(255, 255, 255, 1), 3px 3px 2px 0px rgba(0, 0, 0, 0.1) inset',
                            }}
                        >
                            <div style={{ textAlign: 'center', alignItems: "center", color: 'rgba(173, 173, 173, 1)'}}>
                                <p>{t('general.withdrawalSummary')}</p>
                            </div>
                            <div className={styles.newDividerClass} />
                            <div style={{ display: 'flex', justifyContent: 'space-between', textAlign: "cneter", color: 'rgba(120, 120, 120, 1)'}}>
                                <p>{t('general.rate')}</p>
                                <p>$ {currencyFormat(currentPrice, 3)}</p>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', textAlign: "cneter", color: 'rgba(120, 120, 120, 1)'}}>
                                <p>{t('title.withdraw')}</p>
                                <p>{ !viewSummary ? '$' : null } { currencyFormat(amount, 2) } { viewSummary ? t('general.unit') : null }</p>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', textAlign: "cneter", color: 'rgba(120, 120, 120, 1)'}}>
                                <p>{t('general.withdrawalFee')}</p>
                                <p>{ !viewSummary ? '$' : null } { currencyFormat(fee, 2) } { viewSummary ? t('general.unit') : null }</p>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', textAlign: "cneter", color: 'rgba(120, 120, 120, 1)'}}>
                                <p style={{ fontWeight:  700 }}>{t('general.totalAmount')}</p>
                                <p style={{ fontWeight:  700 }}>{ !viewSummary ? '$' : null } { currencyFormat(total, 2) } { viewSummary ? t('general.unit') : null }</p>
                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant='body2' style={{color: 'rgba(120, 120, 120, 1)' }}>
                            <span style={{ fontWeight: 700 }}>{t('withdraw.remark')}</span> : { state.remark }
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                   
                        <TextField
                            variant="standard"
                            fullWidth
                            name="securityPassword"
                            placeholder={t('profile.currentSecurityPassword')}
                            type={showPassword.security_password ? 'text' : 'password'}
                            value={state.securityPassword || ''}
                            onChange={handleChange}
                            helperText={inputErrors && inputErrors.security_password ? inputErrors.security_password : ''}
                            error={inputErrors && inputErrors.security_password ? true : false}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                disableUnderline: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => handleClickShowPassword('securityPassword')}
                                        >
                                            {showPassword.security_password ? <FiEye className="img-style" /> : <FiEyeOff className="img-style" />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            className={styles.textFieldBox}
                        />
                    </Grid>
                </Grid>

                <Button
                    variant="contained"
                    fullWidth
                    className='big-button'
                    style={{ margin: '10px 0' }}
                    onClick={nextFunction()}
                    disabled={submitAction}
                >
                    {t('button.withdrawNow')}
                </Button>

                <Button
                    variant="contained"
                    fullWidth
                    className='big-button'
                    onClick={() => setPreview(false)}
                    sx={{
                        margin: '10px 0',
                        backgroundColor: 'rgba(238, 238, 238, 1)',
                        border: '2px solid rgba(203, 32, 32, 1)',
                        boxShadow: 'unset',
                        color: theme.palette.primary.main,
                        '&:hover': {
                            backgroundColor: 'rgba(203, 32, 32, 1)',
                            boxShadow: '2px 2px 3px 0px rgba(0, 0, 0, 0.1), 2px 2px 4px 0px rgba(255, 255, 255, 0.52) inset, -2px -2px 4px 0px rgba(0, 0, 0, 0.13) inset',
                            color: 'rgba(238, 238, 238, 1)',
                            border: 'unset',
                        },
                    }}
                >
                    {t('button.back')}
                </Button>

            </>
        )
    }

    const twofa = () => {
        return (
            <TwoFA
                inputErrors={inputErrors?.authentication_code}
                open={open}
                onClose={() => setOpen(false)}
                value={state?.authenticationCode || ''}
                handleChange={handleChange}
                onClick={submitData}
                name={"authenticationCode"}
            />
        )
    }

    const dialogForm = () => {
        return (
            <Dialog open={dialogBoxOpen} onClose={() => setDialogBoxOpen(false)}>
                <DialogContent>

                    <div 
                        style={{
                            margin: '10px 15px 25px',
                        }}
                    >
                        <Typography variant='h5' style={{color: theme.palette.darkBase.main }} >{t('merchant.notice')}</Typography>
                        <Typography variant='body2' style={{color: theme.palette.darkBase.main }} >{t('merchant.noticeMessage')}</Typography>
                    </div>

                    <div 
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}
                    >
                        <Button 
                            onClick={() => setDialogBoxOpen(false)}
                            variant="contained"
                            sx={{
                                "&.MuiButton-root": {
                                    backgroundColor: theme.palette.darkBase.main,
                                    color: theme.palette.white.main,
                                },
                                "&.Mui-disabled": {
                                    backgroundColor: "#D9D9D9",
                                    color: "#ADADAD",
                                    boxShadow: '2px 2px 4px 0px #00000040 inset',
                                },
                            }}
                        >
                            {t('button.back')}
                        </Button>

                        <Button 
                            onClick={() => submitData()}
                            variant="contained"
                            sx={{
                                "&.Mui-disabled": {
                                    backgroundColor: "#D9D9D9",
                                    color: "#ADADAD",
                                    boxShadow: '2px 2px 4px 0px #00000040 inset',
                                },
                            }}
                        >
                            {t('button.submit')}
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        )
    }

    return (
        <div className='dashboard-container'>
            <div className='dashboard-w' style={{paddingBottom:40}}>
                <div className='pos-relative' style={{zIndex:2}}>
                    { preview ? step2() : step1() }
                    { dialogForm() }
                    { twofa() }
                </div>
            </div>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    newDividerClass: {
        backgroundColor: theme.palette.white.main,
        boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.12) inset, 0px 2px 3px 0px rgba(255, 255, 255, 1)',
        height: 4,
        display: 'inline-block',
        border: 0,
        width: '100%'
    }
}));

export default TradeMerchantWithdraw;