import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { Grid, Typography, Link } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';

export default function MerchantPreview(props) {
    const { state, logoImage, countries, countryState } = props;
    const { t } = useTranslation();

    const theme = useTheme();
    const styles = useStyles();
    const containerRef = useRef(null);

    const [isRight, setIsRight] = useState(true);
    const [isLeft, setIsLeft] = useState(false);
    const [hasScroll, setHasScroll] = useState(false);

    // --------------- Api -------------------
    useEffect(() => {
        const container = containerRef.current;
        
        if (container) {
            const checkForScrollbar = () => {
                setHasScroll(container.scrollWidth > container.clientWidth);
            };

            checkForScrollbar();

            const handleScroll = () => {
                let scrolling = container.scrollLeft + container.clientWidth;
                if (scrolling >= (container.scrollWidth - 10)) {
                    setIsRight(false);
                    setIsLeft(true);
                } else if (scrolling > container.clientWidth && scrolling < container.scrollWidth) {
                    setIsRight(true);
                    setIsLeft(true);
                } else if (scrolling <= container.clientWidth) {
                    setIsRight(true);
                    setIsLeft(false);
                }
            };

            // Attach the scroll event listener to the container
            container.addEventListener('scroll', handleScroll);
            window.addEventListener('resize', checkForScrollbar);

            // Clean up the event listener when the component unmounts
            return () => {
                container.removeEventListener('scroll', handleScroll);
                window.removeEventListener('resize', checkForScrollbar);
            };
        }
    }, []);

    let productSellNum = 0;
    if (state?.productSell) {
        productSellNum = _.size(_.split(state?.productSell, ','));
    }

    return (
        <Grid container spacing={4} style={{ textAlign: 'left' }}>
            <Grid item xs={12} md={6}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4} md={12} lg={4}>
                        <div className={styles.logoPreviewDisplayDesign} style={{ backgroundImage: logoImage ? `url('${logoImage}')` : `url(${state?.existDocs?.existLogo?.file_name})` }} />
                    </Grid>
                    <Grid item xs={12} sm={8} md={12} lg={8}>
                        <Typography style={{ color: theme.palette.gray.main }}><i>{state?.code ? state?.code : '-'}</i></Typography>
                        <Typography style={{ color: theme.palette.gray.main }}><b>{state?.name ? state?.name?.en : '-'}</b></Typography>
                        <Typography style={{ color: theme.palette.gray.main }}><b>{state?.name ? state?.name?.cn : '-'}</b></Typography>
                        <Typography style={{ color: theme.palette.gray.main }}><i>{state?.companyRegisterNumber ? state?.companyRegisterNumber : '-'}</i></Typography>
                        <br />

                        <Typography style={{ color: theme.palette.gray.main }}>{state?.website ? state?.website : '-'}</Typography>
                        <Typography style={{ color: theme.palette.gray.main }}>{state?.email ? state?.email : '-'}</Typography>
                        <Typography style={{ color: theme.palette.gray.main }}>+{state?.phone_code ? state?.phone_code : '-'}{state?.phone ? state?.phone : '-'}</Typography>
                    </Grid>
                </Grid>

                <div className='p-t-10'>
                    <Typography style={{ color: '#ADADAD' }}>{t('merchant.address1')}</Typography>
                    <Typography style={{ color: theme.palette.gray.main }}>
                        {state?.address ? state?.address?.address1 : '-'},
                        {state?.address?.zip}&nbsp;
                        {countryState?.length > 0 && _.map(countryState, (detail) => (detail.id === parseInt(state?.address?.state) && detail?.name))},&nbsp;
                        {countries?.length > 0 && _.map(countries, (detail) => (detail.code === state?.address?.country && detail?.name))}
                    </Typography>
                    <br />

                    <Typography style={{ color: theme.palette.gray.main }}>{state?.aboutShop ? state?.aboutShop : '-'}</Typography>
                </div>
            </Grid>
            <Grid item xs={12} md={6}>
                <Typography style={{ color: '#ADADAD' }}>{t('merchant.businessNature')}</Typography>
                <Typography style={{ color: theme.palette.gray.main }}>{state?.businessNature ? state?.businessNature : '-'}</Typography>
                <br />

                <Typography style={{ color: '#ADADAD' }}>{t('merchant.productSell')} ({productSellNum})</Typography>
                <Typography style={{ color: theme.palette.gray.main }}>{state?.productSell ? state?.productSell : '-'}</Typography>
                <br />

                <Typography style={{ color: '#ADADAD' }}>{t('merchant.supportDocuments')} ({_.size(state?.newDocs?.newIcs) + _.size(state?.newDocs?.newSupportDocs) + _.size(state?.existDocs?.existIcs) + _.size(state?.existDocs?.existSupportDocs)})</Typography>
                <div className='p-t-10' style={{ position: 'relative' }} >
                    {
                        isLeft && hasScroll &&
                        <div className='flex-m' style={{ background: `linear-gradient(to left, transparent, ${theme.palette.darkGray.main})`, height: '100%', position: 'absolute', top: 0, left: 0 }}>
                            <ArrowBackIos style={{ paddingLeft: 5, fontSize: 30 }} />
                        </div>
                    }

                    <div ref={containerRef} className='scrollable-container p-b-10' style={{ display: 'flex', overflowX: 'scroll' }}>
                        {
                            state?.newDocs?.newIcFront &&
                            <div className='m-r-15 flex-col-c'>
                                <Link target="_blank" href={state?.newDocs?.newIcFront?.file_name} underline='none' style={{ width: '80%' }} >
                                    <div className={styles.previewDocsDesign} style={{ padding: 10 }}>
                                        <div className={styles.imagePreviewDisplayDesign} style={{ backgroundImage: `url(images/merchants/ic_icon.png)`, objectFit: 'contain' }} />
                                    </div>
                                </Link>
                                <Typography variant='caption' className='p-t-5 txt-center' style={{ width: 80, color: theme.palette.darkGray.main, wordBreak: 'break-all' }}><b>{state?.newDocs?.newIcFront?.name || state?.newDocs?.newIcFront?.descr}</b></Typography>
                            </div>
                        }
                        {
                            state?.newDocs?.newIcBack &&
                            <div className='m-r-15 flex-col-c'>
                                <Link target="_blank" href={state?.newDocs?.newIcBack?.file_name} underline='none' style={{ width: '80%' }} >
                                    <div className={styles.previewDocsDesign} style={{ padding: 10 }}>
                                        <div className={styles.imagePreviewDisplayDesign} style={{ backgroundImage: `url(images/merchants/ic_icon.png)`, objectFit: 'contain' }} />
                                    </div>
                                </Link>    
                                <Typography variant='caption' className='p-t-5 txt-center' style={{ width: 80, color: theme.palette.darkGray.main, wordBreak: 'break-all' }}><b>{state?.newDocs?.newIcBack?.name || state?.newDocs?.newIcBack?.descr}</b></Typography>
                            </div>
                        }
                        {
                            state?.existDocs?.existIcFront &&
                            <div className='m-r-15 flex-col-c'>
                                <Link target="_blank" href={state?.existDocs?.existIcFront?.file_name} underline='none' style={{ width: '80%' }} >
                                    <div className={styles.previewDocsDesign} style={{ padding: 10 }}>
                                        <div className={styles.imagePreviewDisplayDesign} style={{ backgroundImage: `url(images/merchants/ic_icon.png)`, objectFit: 'contain' }} />
                                    </div>
                                </Link>
                                <Typography variant='caption' className='p-t-5 txt-center' style={{ width: 80, color: theme.palette.darkGray.main, wordBreak: 'break-all' }}>{state?.existDocs?.existIcFront?.name || state?.existDocs?.existIcFront?.descr}</Typography>
                            </div>
                        }
                        {
                            state?.existDocs?.existIcBack &&
                            <div className='m-r-15 flex-col-c'>
                                <Link target="_blank" href={state?.existDocs?.existIcBack?.file_name} underline='none' style={{ width: '80%' }} >
                                    <div className={styles.previewDocsDesign} style={{ padding: 10 }}>
                                        <div className={styles.imagePreviewDisplayDesign} style={{ backgroundImage: `url(images/merchants/ic_icon.png)`, objectFit: 'contain' }} />
                                    </div>
                                </Link>    
                                <Typography variant='caption' className='p-t-5 txt-center' style={{ width: 80, color: theme.palette.darkGray.main, wordBreak: 'break-all' }}>{state?.existDocs?.existIcBack?.name || state?.existDocs?.existIcBack?.descr}</Typography>
                            </div>
                        }
                        {
                            _.size(state?.newDocs?.newSupportDocs) > 0 &&
                            _.map(state?.newDocs?.newSupportDocs, (doc, idx) => (
                                <div key={idx} className='m-r-15 flex-col-c'>
                                    <div className={styles.previewDocsDesign} style={{ padding: 10 }}>
                                        <div className={styles.imagePreviewDisplayDesign} style={{ backgroundImage: `url(images/merchants/pdf_icon.png)`, objectFit: 'contain' }} />
                                    </div>
                                    <Typography variant='caption' className='p-t-5 txt-center' style={{ width: 80, color: theme.palette.darkGray.main, wordBreak: 'break-all' }}><b>{doc?.name || doc?.descr}</b></Typography>
                                </div>
                            ))
                        }
                        {
                            _.size(state?.existDocs?.existSupportDocs) > 0 &&
                            _.map(state?.existDocs?.existSupportDocs, (doc, idx) => (
                                <div key={idx} className='m-r-15 flex-col-c'>
                                    <div className={styles.previewDocsDesign} style={{ padding: 10 }}>
                                        <div className={styles.imagePreviewDisplayDesign} style={{ backgroundImage: `url(images/merchants/pdf_icon.png)`, objectFit: 'contain' }} />
                                    </div>
                                    <Typography variant='caption' className='p-t-5 txt-center' style={{ width: 80, color: theme.palette.darkGray.main, wordBreak: 'break-all' }}>{doc?.name || doc?.descr}</Typography>
                                </div>
                            ))
                        }
                    </div>

                    {
                        isRight && hasScroll &&
                        <div className='flex-m' style={{ background: `linear-gradient(to right, transparent, ${theme.palette.darkGray.main})`, height: '100%', position: 'absolute', top: 0, right: 0 }}>
                            <ArrowForwardIos style={{ paddingRight: 5, fontSize: 30 }} />
                        </div>
                    }
                </div>
            </Grid>
        </Grid>
    )
}

const useStyles = makeStyles(theme => ({
    previewDocsDesign: {
        width: 60,
        aspectRatio: 1/1,
        alignContent: 'center',
        boxShadow: '-3px -3px 4px 0px #FFFFFF, 3px 3px 5px 0px #0000001A',
        borderRadius: 10,
    },
    imagePreviewDisplayDesign: {
        width: '100%',
        aspectRatio: 1/1,
        objectPosition: 'center',
        overflow: 'hidden',
        borderRadius: 5,
        backgroundSize: 'auto 100%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    },
    logoPreviewDisplayDesign: {
        width: '100%',
        aspectRatio: 1/1,
        objectPosition: 'center',
        borderRadius: 5,
        backgroundSize: 'auto 100%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        boxShadow: '2px 2px 4px 0px #00000040 inset', 
        objectFit: 'cover',
    },
}));