import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { getUrl, postUrl } from '@utils/ApiAction';
import { useTheme, makeStyles } from '@mui/styles';
import useNotificationLoading from '@utils/useNotificationLoading';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { Typography, FormControl, Button, IconButton, TextField, Box, RadioGroup, FormControlLabel, Radio, InputAdornment, FormHelperText, Select,MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { currencyFormat, storePaths } from '@utils/Tools';

import { FiEye, FiEyeOff } from "react-icons/fi";

const INITIAL_STATE = { 
    type: 'unit', 
    redeemableValue: '', 
    unit: '', 
    currentPrice: '', 
    tradeDecimal: 0, 
    securityPassword: '' ,
    valueType : 'system'
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        },
    },
}; 

const Convert = () => {
    const theme = useTheme();
    let location = useLocation();
    const navigate = useNavigate();
    const isMountedRef = useRef(null);
    const { t, i18n } = useTranslation();
    const { id } = useSelector(state => state.user);
    const [merchant, setMerchant] = useState(null);
    const [inputErrors, setInputErrors] = useState([]);
    const [state, setState] = useState(INITIAL_STATE);
    const [activeStep, setActiveStep] = useState(0);
    const [option, setOption] = useState({
        securityPasswordShow: false,
    });
    const { addAlert, setLoading, loading } = useNotificationLoading();

    useEffect(() => {
        callApi();
        // eslint-disable-next-line
    }, []);

    const goToLink = (link) => {
        navigate(link, { state: { from: storePaths(location) } })
    };

    const callApi = () => {
        isMountedRef.current = true;
        setLoading(true);
        getUrl(`/member-merchant-profile`).then(response => {
            if (isMountedRef.current) {
                setLoading(false);
                setMerchant(response?.data?.merchant);
                setState(prevState => ({
                    ...prevState,
                    currentPrice: response?.data?.current_price,
                    tradeDecimal: 2
                }));
            }
        }).catch(error => {
            if (isMountedRef.current) {
                setLoading(false);
                setMerchant(null);
                addAlert(JSON.stringify(error.message));
            }
        });
        return () => { isMountedRef.current = false };
    }

    const handleChange = ({ target }) => {
        let { name, value } = target;
        switch (name) {
            case 'redeemableValue':
                // Remove all non-numeric characters except the decimal point
                value = value.replace(/[^0-9.]/g, '');

                // Ensure only one decimal point is present
                const decimalCount = value.split('.').length - 1;
                if (decimalCount > 1) {
                    value = value.slice(0, value.lastIndexOf('.'));
                }

                // Limit decimal places to three
                const parts = value.split('.');
                if (parts[1] && parts[1].length > 3) {
                    value = `${parts[0]}.${parts[1].slice(0, 3)}`;
                }

                let cUnit = Math.ceil(value / state?.currentPrice);
                setState({ ...state, [name]: value, unit: cUnit });
                break;
            case 'unit':
                value = !/^\s*$/.test(value) && !isNaN(value) ? value : "";
                let cRedeemValue = '';
                if (value !== "") {
                    cRedeemValue = value * state?.currentPrice;
                }
                setState({ ...state, [name]: value, redeemableValue: cRedeemValue });
                break;
            default:
                setState({ ...state, [name]: value });
                break;
        }
    };

    const handleConvert = () => {
        setInputErrors([]);
        let params = {
            user_id: id,
            merchant_id: merchant?.id,
            type: state?.type,
            current_price: state?.currentPrice,
            security_password: state?.securityPassword,
            value_type: state?.valueType,
        }

        if (state?.type === 'unit') {
            params['unit'] = state?.unit
        } else if (state?.type === 'redeemable_value') {
            params['redeemable_value'] = state?.redeemableValue;
        }
        postUrl(`/trade-merchant-convert`, params).then(response => {
            setLoading(false);
            let { status, message, data, errors } = response;

            if (status) {
                setState(INITIAL_STATE);
                setActiveStep(0);
                callApi();
                addAlert('', message || t('success.convertSuccess'), 'success', '');

            } else {
                setInputErrors(errors);
                if (_.size(errors) > 0) {
                    let errorKeys = Object.keys(errors);
                    if (_.size(_.intersection(['redeemable_value', 'unit'], errorKeys)) > 0) {
                        handleBack();
                    }
                }
                addAlert('', message || t('error.contactSupport'), 'error', '');
            }

        }).catch(error => {
            setLoading(false);
            setInputErrors([]);
            addAlert('', JSON.stringify(error.message), 'error', '');
        });
    }

    const handleRadioChange = (event) => {
        setState({ ...state, type: event.target.value, unit: '', redeemableValue: '' });
    };

    const handleMaxButtonClick = () => {
        if (state?.type === 'unit') {
            let cRedeemValue = merchant?.merchant_trade_profile?.passive_unit * state?.currentPrice;
            setState({ ...state, unit: merchant?.merchant_trade_profile?.passive_unit, redeemableValue: cRedeemValue });
        } else {
            if(state.valueType === 'system'){
                let cUnit = Math.ceil(merchant?.merchant_trade_profile?.withdrawable_value / state?.currentPrice);
                setState({ ...state, redeemableValue: merchant?.merchant_trade_profile?.withdrawable_value, unit: cUnit });
            }else if(state.valueType === 'redeem'){
                let cUnit = Math.ceil(merchant?.merchant_trade_profile?.redeemable_value / state?.currentPrice);
                setState({ ...state, redeemableValue: merchant?.merchant_trade_profile?.redeemable_value, unit: cUnit });
            }else{
                addAlert('', t('spend.maxButtonDisable'), 'error', '');
            }
            
        }
    };

    const handleNext = () => {
        if (state?.type === 'unit') {
            if (state?.unit > 0) {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
            else {
                addAlert('', t('error.requiredFillAllSection'), 'error', '');
            }
        } else if (state?.type === 'redeemable_value') {
            if (state?.redeemableValue > 0) {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
            else {
                addAlert('', t('error.requiredFillAllSection'), 'error', '');
            }
        }
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handlePasswordShow = (name) => {
        setOption({ ...option, [name]: !option[name] });
    }

    const stepOne = () => {
        return (
            <Box>
                <Typography variant='h5' style={{ textAlign: 'center', color: theme.palette.darkBase.main, fontWeight: 700, textTransform: 'uppercase' }} >
                    <b>
                        {t('profile.convert')}
                    </b>
                </Typography>
                <div>
                    <Box className="m-tb-30">
                        <div className='flex-c-t'>
                            <div>
                                {merchant?.logo ? (
                                    <img
                                        src={merchant?.logo?.file_name}
                                        alt={`Default Merchant Logo - ${merchant?.code}`}
                                        style={{ maxWidth: '110px', maxHeight: '110px' }}
                                    />
                                ) : (
                                    <img
                                        src="/images/logos/logo.png"
                                        alt={`Default Merchant Logo - ${merchant?.code}`}
                                        style={{ maxWidth: '110px', maxHeight: '110px' }}
                                    />
                                )}
                            </div>
                            <div className='s-full dis-flex flex-sa-l' style={{ marginLeft: 20, flexDirection: 'column' }}>
                                <div className='dis-flex flex-sa-l w-full' style={{ flexDirection: 'column', gap: '10px' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant="h6" style={{ color: theme.palette.gray.main, fontWeight: 'bold' }}>
                                            {merchant?.name[i18n.language] ? merchant?.name[i18n.language] : merchant?.name?.cn}
                                        </Typography>
                                        <Typography variant="body2" style={{ fontStyle: 'italic', color: theme.palette.darkGray.main }}>
                                            {`${t('spend.redeemableValue')}: `}
                                            <b>{`$ ${currencyFormat(isNaN(merchant?.merchant_trade_profile?.redeemable_value) ? 0 : merchant?.merchant_trade_profile?.redeemable_value, state?.tradeDecimal) || 0}`}</b>
                                        </Typography>
                                        <Typography variant="body2" style={{ fontStyle: 'italic', color: theme.palette.darkGray.main }}>
                                            {`${t('spend.systemRedeemableValue')}: `}
                                            <b>{`$ ${currencyFormat(isNaN(merchant?.merchant_trade_profile?.withdrawable_value) ? 0 : merchant?.merchant_trade_profile?.withdrawable_value, state?.tradeDecimal) || 0}`}</b>
                                        </Typography>
                                        <Typography variant="body2" style={{ fontStyle: 'italic', color: theme.palette.darkGray.main }}>
                                            {`${t('spend.passiveUnit')}: `}
                                            <b>{currencyFormat(isNaN(merchant?.merchant_trade_profile?.passive_unit) ? 0 : merchant?.merchant_trade_profile?.passive_unit, 0) || 0}</b>
                                        </Typography>
                                    </div>
                                    <Typography variant="body2" style={{ fontStyle: 'italic', color: theme.palette.darkGray.main }}>
                                        {`${t('trade.currentPrice')}: $ ${state?.currentPrice}`}
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    </Box>
                </div>
                <FormControl>
                    <RadioGroup row value={state?.type} onChange={handleRadioChange}>
                        <FormControlLabel
                            value="unit"
                            control={<Radio />}
                            label={t('general.unit')}
                            sx={{
                                marginRight: 3,
                                '& .MuiTypography-root': {
                                    color: theme.palette.gray.main,
                                    fontWeight: 400,
                                    fontSize: 17
                                }
                            }}
                        />
                        <FormControlLabel
                            value="redeemable_value"
                            control={<Radio />}
                            label={t('general.value')}
                            sx={{
                                '& .MuiTypography-root': {
                                    color: theme.palette.gray.main,
                                    fontWeight: 400,
                                    fontSize: 17
                                }
                            }}
                        />
                    </RadioGroup>
                </FormControl>
                <Box>
                    <TextField
                        placeholder={state?.type === 'redeemable_value' ? t('spend.redeemableValue') : t('spend.passiveUnit')}
                        variant='standard'
                        fullWidth
                        required
                        name={state?.type === 'redeemable_value' ? "redeemableValue" : "unit"}
                        value={state?.type === 'redeemable_value' ? state?.redeemableValue || '' : state?.unit || ''}
                        onChange={handleChange}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Button variant="contained" className='max-button' onClick={handleMaxButtonClick}>
                                        {t('general.max')}
                                    </Button>
                                </InputAdornment>
                            ),
                        }}
                    />
                    {
                        inputErrors && (inputErrors.unit || inputErrors.redeemable_value) ? (
                            <Typography color="error" style={{ margin: "0px 15px", fontSize: "12px" }}>
                                {[inputErrors.unit, inputErrors.redeemable_value].filter(Boolean).join(', ')}
                            </Typography>
                        ) : null
                    }
                    <Box display="flex" justifyContent="space-between" style={{ marginTop: "10px" }}>
                        <Typography style={{ fontSize: "13px", fontWeight: 400, color: theme.palette.darkGray.main, fontStyle: 'italic' }}>
                            {`≈  ${state?.type === 'unit' ? `$ ${currencyFormat(isNaN(state?.redeemableValue) ? 0 : state?.redeemableValue, state?.tradeDecimal)}` : `${currencyFormat(isNaN(state?.unit) ? 0 : state?.unit, 0)} ${t('general.unit')}`} `}
                        </Typography>
                        <Typography style={{ fontSize: "13px", fontWeight: 400, color: theme.palette.darkGray.main }}>
                            {state?.type === 'unit' ? t('trade.maxConvertPassiveUnit') : t('trade.maxConvertRedeemableValue')}: <b> {state?.type === 'unit' ? currencyFormat(isNaN(merchant?.merchant_trade_profile?.passive_unit) ? 0 : merchant?.merchant_trade_profile?.passive_unit, 0) : `$${currencyFormat(isNaN(merchant?.merchant_trade_profile?.redeemable_value) ? 0 : merchant?.merchant_trade_profile?.redeemable_value, state?.tradeDecimal)}`} </b>
                        </Typography>
                    </Box>
                </Box>
                <FormControl sx={{ width: '100%' }} variant="standard"  error={inputErrors && inputErrors['country'] ? true : false}>
                    <Select
                        id="valueType"
                        name="valueType"
                        value={state.valueType?state.valueType:""}
                        onChange={handleChange}
                        displayEmpty
                        MenuProps={MenuProps}
                        sx={{
                            '& .MuiSelect-select': {
                                border: 'none',
                                borderRadius: 10,
                                boxShadow: 'none',
                                color: '#2E3133',
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: 'none',
                                borderRadius: 10,
                                boxShadow: 'none',
                            },
                            '& .MuiSelect-icon': {
                                color: '#2E3133',
                                marginRight:"10px"
                            },
                        }}
                    >
                        <MenuItem value="" style={{ color: '#222' }} disabled>
                            {t('general.pleaseSelect')}
                        </MenuItem>
                        <MenuItem value="system" style={{ color: '#222' }} >
                            {t('spend.systemRedeemableValue')}
                        </MenuItem>
                        <MenuItem value="redeem" style={{ color: '#222' }} >
                            {t('spend.redeemableValue')}
                        </MenuItem>
                    </Select>
                    {
                        inputErrors && inputErrors['value_type'] &&
                        <FormHelperText style={{ color: 'red' }}>{inputErrors['value_type']}</FormHelperText>
                    }
                </FormControl>
                <Box className="flex-c-m" style={{ marginTop: 30 }}>
                    <Button variant="contained" className='big-button w-full' onClick={handleNext} disabled={!merchant?.extra_setting?.convert?.available}>{t('button.convertNow')}</Button>
                </Box>
            </Box>
        )
    }

    const stepTwo = () => {
        return (
            <Box>
                <Typography variant='h6' style={{ textAlign: 'center', color: theme.palette.gray.main, fontWeight: 700, textTransform: 'uppercase' }} >
                    <b>
                        {t('trade.convertDetail')}
                    </b>
                </Typography>
                <div>
                    <Box className="m-tb-30">
                        <div className='flex-c-m' style={{ flexDirection: 'column' }}>
                            <div>
                                {merchant?.logo ? (
                                    <img
                                        src={merchant?.logo?.file_name}
                                        alt={`Default Merchant Logo - ${merchant?.code}`}
                                        style={{ maxWidth: '110px', maxHeight: '110px' }}
                                    />
                                ) : (
                                    <img
                                        src="/images/logos/logo.png"
                                        alt={`Default Merchant Logo - ${merchant?.code}`}
                                        style={{ maxWidth: '110px', maxHeight: '110px' }}
                                    />
                                )}
                            </div>
                            <div>
                                <Typography variant="body2" style={{ color: theme.palette.darkGray.main, textTransform: 'uppercase', fontStyle: 'italic' }}>{` ${t('trade.merchant')} : ${merchant?.name[i18n.language] ? merchant?.name[i18n.language] : merchant?.name?.cn}`}</Typography>
                            </div>
                        </div>
                    </Box>
                </div>
                <div style={{ backgroundColor: theme.palette.white.main, boxShadow: '3px 3px 4px 0px #FFFFFF,3px 3px 2px 0px #0000001A inset', borderRadius: 20, padding: 20 }}>
                    <div className='flex-c-m' style={{ flexDirection: 'column' }}>
                        <Typography variant="body1" style={{ color: '#adadad', fontWeight: 'bold', textAlign: 'center' }}>{t('trade.approximatelyConvertSummary')}</Typography>
                        <hr
                            style={{
                                backgroundColor: '#F1F1F1',
                                boxShadow: '0px 1px 2px 0px #0000001F inset, 0px 2px 3px 0px #FFFFFF',
                                height: 4,
                                display: 'inline-block',
                                border: 0,
                                width: '100%'
                            }}
                        />
                        <div className='flex-sb-m w-full'>
                            <div className='flex-l-m'>
                                <Typography variant="body2" style={{ color: theme.palette.gray.main }}>{state?.type === 'redeembale_value' ? t('trade.convertValue') : t('trade.convertUnit')}</Typography>
                            </div>
                            <div>
                                <Typography variant="body2" style={{ color: theme.palette.gray.main, fontStyle: 'italic' }}>
                                    {`${state?.type === 'redeemable_value' ? `$ ${currencyFormat(state?.redeemableValue, state?.tradeDecimal)}` : `${currencyFormat(state?.unit, 0) || 0} ${t('general.unit')}`}`}
                                </Typography>
                            </div>
                        </div>
                        <div className='flex-sb-m w-full'>
                            <div className='flex-l-m'>
                                <Typography variant="body2" style={{ color: theme.palette.gray.main }}>{t('trade.convertRate')}</Typography>
                            </div>
                            <div>
                                <Typography variant="body2" style={{ color: theme.palette.gray.main, fontStyle: 'italic' }}>
                                    {state?.currentPrice}
                                </Typography>
                            </div>
                        </div>
                        <div className='flex-sb-m w-full' style={{ marginTop: 10 }}>
                            <div className='flex-l-t'>
                                <Typography variant="body2" style={{ color: theme.palette.gray.main, fontWeight: 'bolder' }}>{t('trade.totalConvert')}</Typography>
                            </div>
                            <div className='dis-flex' style={{ flexDirection: 'column' }}>
                                <Typography variant="body2" style={{ color: theme.palette.gray.main, fontWeight: 'bolder', textTransform: 'uppercase', textAlign: 'right' }}>
                                    {state?.type === 'redeemable_value' ? `$ ${currencyFormat(state?.redeemableValue, state?.tradeDecimal)}` : `${currencyFormat(state?.unit, 0)} ${t('general.unit')}`}
                                </Typography>
                                <Typography variant="caption" style={{ color: theme.palette.gray.main, textTransform: 'uppercase' }}>
                                    {`≈ ${state?.type === 'redeemable_value' ? `${currencyFormat(state?.unit, 0)} ${t('general.unit')}` : `$ ${currencyFormat(state?.redeemableValue, state?.tradeDecimal)}`}`}
                                </Typography>
                            </div>
                        </div>
                    </div>
                </div>
                <Box style={{ marginTop: 20 }}>
                    <TextField
                        variant="standard"
                        fullWidth
                        name="securityPassword"
                        placeholder={t(`user.securityPassword`)}
                        value={state?.securityPassword}
                        onChange={handleChange}
                        type={option?.securityPasswordShow ? 'text' : 'password'}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => handlePasswordShow('securityPasswordShow')}>
                                        {option?.securityPasswordShow ? <FiEye className="img-style" style={{ color: theme.palette.inputIconColor }} /> : <FiEyeOff className="img-style" style={{ color: theme.palette.inputIconColor }} />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                            disableUnderline: true
                        }}
                        helperText={inputErrors && inputErrors.security_password ? inputErrors.security_password : ''}
                        error={inputErrors && inputErrors.security_password ? true : false}
                        InputLabelProps={{ shrink: true }}
                    />
                </Box>
                <Box className="flex-c-m" style={{ marginTop: 30, flexDirection: 'column', gap: 20 }}>
                    <Button variant="contained" className='big-button w-full' onClick={handleConvert} style={{ textTransform: 'uppercase' }} disabled={!merchant?.extra_setting?.convert?.available}>{t('button.convertNow')}</Button>
                    <Button variant="contained" onClick={handleBack} style={{ background: 'transparent', textTransform: 'uppercase', border: `2px solid ${theme.palette.primary.main}`, }} fullWidth>
                        <Typography style={{ color: theme.palette.primary.main, fontWeight: 'bolder' }}>{t('button.back')}</Typography>
                    </Button>
                </Box>
            </Box>
        )
    }

    const stepperDisplay = (currStep) => {
        switch (currStep) {
            case 0:
                return stepOne();
            case 1:
                return stepTwo();
            default: 
                return  null;
        }
    }

    return (
        <>
            <div className='dashboard-container'>
                <div className='dashboard-w'>
                    <div className='pos-relative' style={{ zIndex: 2 }}>
                        {
                            loading ? null :
                                <>
                                    {
                                        _.size(merchant) > 0 ?
                                            (
                                                merchant?.extra_setting?.convert?.available ?
                                                    <>
                                                        {stepperDisplay(activeStep)}
                                                    </>
                                                    :
                                                    <div className='flex-c-m'>
                                                        <p className='txt-center' style={{ fontWeight: 500, color: theme.palette.darkBase.main }}>{t('general.convertPermissionDenied')}</p>
                                                    </div>
                                            )
                                            :
                                            <div className='flex-c-m' style={{ flexDirection: 'column' }}>
                                                <p className='txt-center p-t-20 p-b-30' style={{ fontWeight: 500, color: theme.palette.darkBase.main }}>{t('general.notMerchant')}</p>
                                                <div className="w-full" style={{ textAlign: 'center', padding: 5 }}>
                                                    <Button variant="contained" className='big-button w-full' onClick={() => goToLink("/become-merchant")}>{t('button.becomeMerchant')}</Button>
                                                </div>
                                            </div>
                                    }
                                </>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default Convert;