import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import _ from 'lodash';

import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/styles';

import { storePaths, buildFormData } from '@utils/Tools';
import useNotificationLoading from '@utils/useNotificationLoading';
import { getUrl, postUrl } from '@utils/ApiAction';

import MerchantIndex from './RegisterMerchant';
import ProcessMerchant from './ProcessMerchant';

const INITIAL_STATE = {
    name: {
        en: '',
        cn: '',
    },
    email: '',
    phone_country: '',
    phone_code: '',
    phone: '',
    code: '',
    companyRegisterNumber: '',
    website: '',
    businessNature: '',
    aboutShop: '',
    productSell: '',
    address: {
        id: '',
        country: '',
        state: '',
        city: '',
        zip: '',
        province: '',
        address1: '',
    },
    existDocs: {
        existLogo: '',
        existIcs: [],
        existIcFront: '',
        existIcBack: '',
        existSupportDocs: {},
    },
    newDocs: {
        newLogo: '',
        newIcs: [],
        newIcFront: '',
        newIcBack: '',
        newSupportDocs: {},
    },
    // newLogo: '',
    // ic: {},
    // icFront: '',
    // icBack: '',
    // supportDoc: {},
    remarkLog: {},
    status: 0,
}

export default function BecomeMerchant() {
    const theme = useTheme();
    let location = useLocation();
    let navigate = useNavigate();
    const mobileView = useMediaQuery((theme) => theme.breakpoints.down('md'));

    const { t } = useTranslation();
    const { addAlert , setLoading , loading } = useNotificationLoading();
    const { merchant } = useSelector(state => state.user);

    const [state, setState] = useState(INITIAL_STATE);
    const [hasData, setHasData] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [resubmitForm, setResubmitForm] = useState(false);
    const [inputErrors, setInputErrors] = useState({});
    const [checked, setChecked] = useState(false);
    const [logoImage, setLogoImage] = useState('');
    const [icFront, setIcFront] = useState(false);
    const [icBack, setIcBack] = useState(false);
    const [supportDocs, setSupportDocs] = useState(false);

    // --------------- Api -------------------
    useEffect(() => {
        try {
            callApi();
        } catch (error) {
            addAlert('', t('error.contactSupport'), 'error', '');
        }
        // eslint-disable-next-line
    }, []);

    // --------------- Functions -------------------
    const callApi = () => {
        setLoading(true);
        getUrl(`/member-get-merchant`).then(response => {
            if (response.status) {
                if (_.size(response.merchant) > 0) {
                    if (response.merchant?.status === 20) {
                        navigate('/', { state: { from: storePaths(location) } })
                    } else {
                        let tempArray = [];
                        if (response?.merchant?.ic_front) {
                            tempArray.push(response?.merchant?.ic_front);
                        }
                        if (response?.merchant?.ic_back) {
                            tempArray.push(response?.merchant?.ic_back);
                        }
                        setHasData(true);
                        setSubmitSuccess(true);
                        setState({
                            ...state,
                            id: response?.merchant?.id,
                            name: response?.merchant?.name,
                            email: response?.merchant?.email || '',
                            phone_code: response?.merchant?.mobile_country_code || '',
                            phone: response?.merchant?.mobile || '',
                            code: response?.merchant?.code || '',
                            companyRegisterNumber: response?.merchant?.company_register_number || '',
                            website: response?.merchant?.website || '',
                            businessNature: response?.merchant?.bussiness_nature || '',
                            aboutShop: response?.merchant?.about_shop || '',
                            productSell: response?.merchant?.product_sell || '',
                            address: {
                                id: response?.merchant?.addresses[0]?.id,
                                address1: response?.merchant?.addresses[0]?.address1,
                                province: response?.merchant?.addresses[0]?.province,
                                zip: response?.merchant?.addresses[0]?.zip,
                                country: response?.merchant?.addresses[0]?.country,
                                state: response?.merchant?.addresses[0]?.state,
                                city: response?.merchant?.addresses[0]?.city,
                                fullAddress: response?.merchant?.addresses[0]?.full_address,
                            },
                            existDocs: {
                                existLogo: response?.merchant?.logo || '',
                                existIcs: _.size(tempArray) > 0 ? tempArray : {},
                                existIcFront: response?.merchant?.ic_front || '',
                                existIcBack: response?.merchant?.ic_back || '',
                                existSupportDocs: response?.merchant?.support_doc || {},
                            },
                            // ic: _.size(tempArray) > 0 ? tempArray : {},
                            // icFront: response?.merchant?.ic_front || '',
                            // icBack: response?.merchant?.ic_back || '',
                            // supportDoc: response?.merchant?.support_doc || {},
                            // newLogo: response?.merchant?.logo || '',
                            status: response?.merchant?.status || '',
                            description: response?.merchant?.description || '',
                            remarkLog: response?.remark || '',
                        });
                    }
                }
            } else {
                let msg = typeof response.message;
                addAlert("", msg !== 'array' ? response.message : t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        }).finally(() => {
            setLoading(false);
        });
    }

    const submitData = () => {
        if (checked) {
            setLoading(true);
            try {
                let apiData = {
                    name: state?.name,
                    code: state?.code,
                    mobile_country_code: state?.phone_code,
                    mobile: state?.phone,
                    email: state?.email,
                    website: state?.website,
                    company_register_number: state?.companyRegisterNumber,
                    bussiness_nature: state?.businessNature,
                    about_shop: state?.aboutShop,
                    product_sell: state?.productSell,
                    new_logo: logoImage ? state?.newDocs?.newLogo?.[0] : '',
                    ic_front: state?.newDocs?.newIcFront ? state?.newDocs?.newIcFront : '',
                    ic_back: state?.newDocs?.newIcBack ? state?.newDocs?.newIcBack : '',
                    support_doc: supportDocs ? state?.newDocs?.newSupportDocs : {},
                    address: {
                        id: state?.address?.id || null,
                        address1: state?.address?.address1,
                        province: state?.address?.province,
                        zip: state?.address?.zip,
                        country: state?.address?.country,
                        state: state?.address?.state,
                        city: state?.address?.city,
                    },
                };

                const formData = new FormData();
                buildFormData(formData, apiData);

                postUrl(`/member-become-merchant`, formData).then(response => {
                    if (response.status) {
                        let tempArray = [];
                        if (response?.data?.ic_front) {
                            tempArray.push(response?.data?.ic_front);
                        }
                        if (response?.data?.ic_back) {
                            tempArray.push(response?.data?.ic_back);
                        }
                        setHasData(true);
                        setSubmitSuccess(true);
                        if (resubmitForm) {
                            setResubmitForm(!resubmitForm);
                        }
                        setState({
                            ...state,
                            id: response?.data?.id,
                            name: response?.data?.name,
                            email: response?.data?.email,
                            mobile_code: response?.data?.mobile_country_code,
                            phone: response?.data?.mobile,
                            code: response?.data?.code,
                            companyRegisterNumber: response?.data?.company_register_number,
                            website: response?.data?.website,
                            businessNature: response?.data?.bussiness_nature,
                            aboutShop: response?.data?.about_shop,
                            productSell: response?.data?.product_sell,
                            address: {
                                country: response?.data?.addresses?.[0]?.country,
                                state: response?.data?.addresses?.[0]?.state,
                                city: response?.data?.addresses?.[0]?.city,
                                zip: response?.data?.addresses?.[0]?.zip,
                                province: response?.data?.addresses?.[0]?.province,
                                address1: response?.data?.addresses?.[0]?.address1,
                                fullAddress: response?.data?.addresses[0]?.full_address,
                            },
                            existDocs: {
                                existLogo: response?.data?.logo || '',
                                existIcs: _.size(tempArray) > 0 ? tempArray : {},
                                existIcFront: response?.data?.ic_front || '',
                                existIcBack: response?.data?.ic_back || '',
                                existSupportDocs: response?.data?.support_doc || {},
                            },
                            // ic: _.size(tempArray) > 0 ? tempArray : {},
                            // icFront: response?.data?.ic_front,
                            // icBack: response?.data?.ic_back,
                            // supportDoc: response?.data?.support_doc,
                            // newLogo: response?.data?.logo,
                            status: response?.data?.status,
                        });
                        addAlert("", response.message, 'success', '');
                    } else {
                        setInputErrors(response.errors);
                        addAlert("", t('error.contactSupport') || response.message , 'error', '');
                    }
                }).catch((error) => {
                    addAlert('', error.message?.errorInfo?.[0] || t('error.contactSupport'), 'error', '');
                }).finally(() => {
                    setLoading(false);
                });
            } catch (error) {
                setLoading(false);
                addAlert('', t('error.contactSupport'), 'error', '');
            }
        } else {
            addAlert('', t('error.acceptTerms'), 'error', '');
        }
    };

    // --------------- End of Lines -------------------

    return (
        <div className='dashboard-container'>
            <div style={{ margin: 'auto', textAlign: 'center', padding: mobileView ? '0px' : '0px 20px' }}>
                {
                    loading 
                    ? null 
                    : hasData  
                        ? state?.status === 20 || resubmitForm || !submitSuccess
                            ? <MerchantIndex hasData={hasData} callApi={callApi} logoImage={logoImage} icFront={icFront} icBack={icBack} supportDocs={supportDocs} setIcFront={setIcFront} setIcBack={setIcBack} setSupportDocs={setSupportDocs} setLogoImage={setLogoImage} state={state} setState={setState} submitData={submitData} inputErrors={inputErrors} setInputErrors={setInputErrors} checked={checked} setChecked={setChecked} />
                            : <ProcessMerchant state={state} setState={setState} setResubmitForm={setResubmitForm} />
                        : <MerchantIndex hasData={hasData} callApi={callApi} logoImage={logoImage} icFront={icFront} icBack={icBack} supportDocs={supportDocs} setIcFront={setIcFront} setIcBack={setIcBack} setSupportDocs={setSupportDocs} setLogoImage={setLogoImage} state={state} setState={setState} submitData={submitData} inputErrors={inputErrors} setInputErrors={setInputErrors} checked={checked} setChecked={setChecked} />
                }
            </div>
        </div>
    );
}