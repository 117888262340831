import React, { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import _ from 'lodash';

import { Box, Button, Grid, IconButton, InputAdornment, Link, TextField, Typography, Hidden,Dialog, DialogContent, DialogActions } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { FiEye, FiEyeOff } from "react-icons/fi";

import { useQuery } from '@utils/Tools';
import { authFail, authSuccess } from '@actions';
import { removeLoginAccess, postUrl, getUrl } from '@utils/ApiAction';
import { storePaths, reducePaths } from '@utils/Tools';
import useNotificationLoading from '@utils/useNotificationLoading';

import AuthTitleBar from '@layouts/AuthTitleBar';

const INITIAL_STATE = {
    username: '',
    password: '',
    showPassword: false,
    type: 'web',
}

export default function Login() {
    const { t } = useTranslation();
    const theme = useTheme();
    const classes = useStyles();
    const [state, setState] = useState(INITIAL_STATE);
    const [inputErrors, setInputErrors] = useState();
    const [disclaimerDialog, setDisclaimerDialog] = useState(false);
    const [stayLoggedInDialog, setStayLoggedInDialog] = useState(false);
    const dispatch = useDispatch();
    const { accessToken } = useSelector(state => state.general);
    const { username } = useSelector(state => state.user);
    const cUrl = useQuery().get('C');
    const paymentCurl = useQuery().get('P');
    const { addAlert, setLoading } = useNotificationLoading();
    let navigate = useNavigate();
    let location = useLocation();

    useEffect(() => {
        if (accessToken) {
            setStayLoggedInDialog(true);
        }
    }, [accessToken]);

    const handleClickShowPassword = () => {
        setState({
            ...state,
            showPassword: !state.showPassword,
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleStayLoggedInResponse = (stay) => {
        setStayLoggedInDialog(false);
        if (stay) {
            setDisclaimerDialog(true);
        } else {
            setState(INITIAL_STATE);
            removeLoginAccess();
        }
    };

    const loginUser = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            let params = {
                username: state?.username,
                password: state?.password,
                type: state?.type,
                direct: location.pathname === "/login-redirect" ? true : false,
            };
    
            const response = await postUrl('/login', params);
            if (response.status) {
                if (location.pathname === "/login-redirect") {
                    if (cUrl && response.data.key !== null) {
                        await dispatch(authSuccess(response.data));
                        let redirect_path = paymentCurl;
                        let paths = paymentCurl ? paymentCurl.split("/") : [];
                        if (_.size(paths) > 0 && paths[0] === 'payment') {
                            redirect_path = paths[0] + '/' + encodeURIComponent(paths[1]);
                        }
                        let navigateLink = cUrl + '?Src=BonusChains&H=' + response.data.key;
                        if (redirect_path) {
                            navigateLink += '&P=' + redirect_path;
                        }
                        window.location.replace(navigateLink);
                    } else {
                        addAlert('', t('error.userNotFound'), 'error', '');
                    }
                } else {
                    await dispatch(authSuccess(response.data));
                    updateDisclaimer(response.data.id);
                    let pathListing = _.size(location?.state) > 0 ? reducePaths(location) : [];
                    let path = _.size(pathListing) > 0 ? _.last(pathListing) : '/';
                    let urlLink = path?.pathname;
                    if (path?.search !== '') {
                        urlLink = urlLink + path?.search;
                    }
                    navigate(urlLink, { state: { from: pathListing } });
                }
            } else {
                setInputErrors(response.errors);
                addAlert('', response.message, 'error', '');
            }
        } catch (error) {
            dispatch(authFail());
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        } finally {
            setLoading(false);
            setDisclaimerDialog(false);
        }
    };    

    const directLoginUser = async () => {
        setLoading(true);
        try {
            const response = await postUrl('/direct-login');
            if (response.status) {    
                if (cUrl && response.data.key !== null) {
                    let redirect_path = paymentCurl;
                    let paths = paymentCurl ? paymentCurl.split("/") : [];
                    if (_.size(paths) > 0 && paths[0] === 'payment') {
                        redirect_path = paths[0] + '/' + encodeURIComponent(paths[1]);
                    }
    
                    let navigateLink = cUrl + '?Src=BonusChains&H=' + response.data.key;
    
                    if (redirect_path) {
                        navigateLink += '&P=' + redirect_path;
                    }
                    window.location.replace(navigateLink);
                } else {
                    addAlert('', t('error.userNotFound'), 'error', '');
                }
            } else {
                setInputErrors(response.errors);
                addAlert('', response.message, 'error', '');
            }
        } catch (error) {
            dispatch(authFail());
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        } finally {
            setLoading(false);
            setDisclaimerDialog(false);
        }
    };

    const agreeDisclaimer = () => {
        if (state?.username && state?.password) {
            setDisclaimerDialog(true);
        } else {
            addAlert('', t('login.loginError'), 'error', '');
        }
    }

    const updateDisclaimer = (id) => {
        postUrl(`/user-agree-disclaimer/${id}`).then(response => {
            if (response.status) {
                // do nothing
            } else {
                addAlert('', response.message, 'error', '');
            }
        }).catch((error) => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    const logout = () => {
        setDisclaimerDialog(false);
        setState(INITIAL_STATE);
        removeLoginAccess();
    };

    const goBack = () => {
        if (_.size(location?.state?.from) > 0) {
            let path = _.size(location?.state?.from) > 0 ? _.last(location?.state?.from) : '/';
            let pathListing = reducePaths(location);
            let urlLink = path?.pathname;
            if (path?.search !== '') {
                urlLink = urlLink + path?.search;
            }
            navigate(urlLink, { state: { from: pathListing } });
        } else {
            navigate('/');
        }
    }

    return (
        <>
            <div className='flex-c-m' style={{ minHeight: '100vh' }}>
                <AuthTitleBar />
                <Hidden mdDown>
                    <div style={{ display: 'flex', flexDirection: 'row', height: '100vh', width: '100%' }}>
                        <div className={classes.leftBg}>
                            <div className='flex-c-m'>
                                <img src="images/auth/login-graphic.png" alt="login-graphic" loading='lazy' className={classes.imgStyle} />
                            </div>
                        </div>
                        <div className={classes.rightBg}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <img src="images/auth/login-black-bg-top.png" alt="bg-black-top" loading='lazy' />
                                <div style={{ display: 'block', bottom: 0, position: 'absolute' }}>
                                    <img src="images/auth/login-black-bg-bottom.png" alt="bg-black-bottom" loading='lazy' />
                                </div>
                            </div>
                        </div>
                    </div>
                </Hidden>
                <div className={classes.loginBox} >
                    <div className='box-auth'>
                        <Typography className='p-b-20 txt-center' style={{fontSize:'1.2rem'}}>{t('login.welcomeBack')}</Typography>
                        <Box>
                            <TextField
                                variant="standard"
                                placeholder={t('user.username')}
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                type="username"
                                autoComplete="username"
                                value={state.username}
                                onChange={({ target }) => setState({ ...state, username: target.value.toLowerCase() })}
                                className={classes.textFieldStyle}
                                helperText={inputErrors && inputErrors.username ? inputErrors.username : ''}
                                error={inputErrors && inputErrors.username ? true : false}
                            />
                            <TextField
                                variant="standard"
                                placeholder={t('user.password')}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {state.showPassword ? <FiEye className="img-style" style={{ color: theme.palette.inputIconColor }} /> : <FiEyeOff className="img-style" style={{ color: theme.palette.inputIconColor }} />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                fullWidth
                                type={state.showPassword ? 'text' : 'password'}
                                color='primary'
                                value={state.password}
                                onChange={({ target }) => setState({ ...state, password: target.value })}
                                className={classes.textFieldStyle}
                                helperText={inputErrors && inputErrors.password ? inputErrors.password : ''}
                                error={inputErrors && inputErrors.password ? true : false}
                            />
                            <div className='flex-r'>
                                <Link component={RouterLink} to={`/forgot-password`} state={{ from: storePaths(location) }} underline="none" style={{ display: 'flex', width: 'fit-content' }}>
                                    <Typography className={`${classes.forgotLink} txt-right`}>{t('forgetPassword.title')}</Typography>
                                </Link>
                            </div>
                            <Button variant="contained" fullWidth color="primary" onClick={agreeDisclaimer} className='big-button' style={{ marginTop: 20, background: theme.palette.primary.main }} >
                                {t('button.login')}
                            </Button>
                        </Box>
                    </div>
                </div>

                <Dialog open={disclaimerDialog} >
                    <Typography variant="h6" color="primary" style={{ padding: '15px 20px', wordBreak: 'break-word' }}>{t('disclaimer.title')}</Typography>
                    <DialogContent >
                        <Trans i18nKey={'disclaimer.info'} components={{ 1: <br /> }} />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant='contained'
                            sx={{
                                "&.MuiButton-root": {
                                    backgroundColor: theme.palette.darkBase.main,
                                    color: theme.palette.white.main,
                                },
                            }}
                            onClick={logout}
                        >
                            {t('button.disagree')}
                        </Button>
                        <Button variant='contained' onClick={accessToken ? directLoginUser : loginUser}>{t('button.agree')}</Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={stayLoggedInDialog}>
                    <Typography variant="h6" color="primary" style={{ padding: '15px 20px', wordBreak: 'break-word' }}>
                        {t('login.stayLoggedInTitle', { username})}
                    </Typography>
                    <DialogContent dividers>
                        <Box display="flex" flexDirection="column" justifyContent="space-between" height="100%">
                            <p variant="body2" style={{ color: 'black' }}>
                                {t('login.stayLoggedInText1', { username })}
                            </p>
                            <p variant="body2" className="m-t-20" style={{ color: 'black' }}>
                                {t('login.stayLoggedInText2')}
                            </p>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={() => handleStayLoggedInResponse(false)}>
                            {t('button.no')}
                        </Button>
                        <Button variant="contained" color="primary" onClick={() => handleStayLoggedInResponse(true)}>
                            {t('button.yes')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </>
    );
}

const useStyles = makeStyles(theme => ({
    textFieldStyle: {
        paddingTop: 10,
        paddingBottom: 10,
    },
    contentBox: {
        paddingTop: '75%',
        paddingBottom: 30,
        paddingLeft: 40,
        paddingRight: 40,
        textAlign: "center",
    },
    titleStyle: {
        fontWeight: "bold",
        textAlign: "center",
        marginBottom: 10
    },
    forgotLink: {
        fontSize: 14,
        color: theme.palette.white.main,
        '&:hover': {
            color: theme.palette.primary.main
        }
    },
    leftBg: {
        flex: 0.3,
        backgroundColor: theme.palette.primary.main,
        backgroundImage: `url('../images/auth/login-red-bg.png')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    rightBg: {
        flex: 0.7
    },
    imgStyle: {
        display: 'block',
        marginTop: 'auto',
        marginBottom: 'auto',
        width: '50%',
        height: '85%',
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: -10,
        right: 0,
        objectFit: 'contain'
    },
    loginBox:{
        position:'absolute',
        display: 'block', 
        marginLeft: 'auto', 
        marginRight: 'auto', 
        right: 0, 
        left: '30%',
        [theme.breakpoints.down('md')]:{
            left:0,
        },
        [theme.breakpoints.down('sm')]:{
            left:0,
        },
        [theme.breakpoints.down('xs')]:{
            left:0,
        },
    }
}));