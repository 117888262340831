import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { Grid, TextField, Typography, FormControl, Select, OutlinedInput, MenuItem, InputLabel, FormHelperText } from '@mui/material';
import { useTheme, makeStyles } from '@mui/styles';
import { KeyboardArrowDown, Autorenew } from '@mui/icons-material';

import { getUrl } from '@utils/ApiAction';
import useNotificationLoading from '@utils/useNotificationLoading';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}; 

export default function MerchantInfo(props) {
    const { state, setState, inputErrors, handleChange } = props;
    const theme = useTheme();
    const styles = useStyles();
    const { t, i18n } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();

    const [mobileCodeList, setMobileCodeList] = useState({
        country: {},
        code: {},
    })
    const [loadCountry, setLoadCountry] = useState(false);

    useEffect(() => {
        setLoadCountry(true);
        getUrl(`/mobile-code-list`).then(response => {
            if (response.status) {
                setLoadCountry(false);
                setMobileCodeList({ country: response?.data?.country_list, code: {} });
            }
        }).catch(error => {
            setLoadCountry(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }, []);

    useEffect(() => {
        if (_.size(mobileCodeList?.country) > 0) {
            if (state?.phone_country !== '') {
                let codeList = {};
                let countryCode = state?.phone_code;
                let code = _.find(mobileCodeList?.country, { 'code': state?.phone_country });

                if (code) {
                    if (_.size(code?.tel_code) > 0) {
                        codeList = code?.tel_code;
                        countryCode = _.size(code?.tel_code) === 1 ? code?.tel_code[0] : (state?.phone_code !== '' ? state?.phone_code : '');
                    }
                }

                setMobileCodeList(prevList => ({ ...prevList, code: codeList }));
                setState(prevState => ({ ...prevState, phone_code: countryCode }));
                // setUserMobile(prevState => ({ ...prevState, code: countryCode }));
            } else if (state?.phone_country === '' && state?.phone_code !== '') {
                let tempArr = _.clone(mobileCodeList?.country);

                tempArr = tempArr.filter((item) => _.includes(item?.tel_code, state?.phone_code));
                if (_.size(tempArr) > 0) {
                    setMobileCodeList(prevList => ({ ...prevList, code: tempArr[0]['tel_code'], updateState: false }));
                    setState(prevState => ({ ...prevState, phone_country: tempArr[0]['code'] }));
                    // setUserMobile(prevState => ({ ...prevState, country: tempArr[0]['code'] }));
                }
            }
        }
    }, [mobileCodeList?.country, state?.phone_country, state?.phone_code]);

    return (
        <>
            <Typography variant='body1' style={{ textAlign: 'left', color: theme.palette.gray.main }}>{t('merchant.name')}</Typography>
            
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <TextField
                        variant="standard"
                        name='merchantNameEn'
                        placeholder={t('merchant.merchantNameEn') + '*'}
                        value={state?.name?.en || ''}
                        onChange={handleChange}
                        helperText={inputErrors && inputErrors.name_en ? inputErrors.name_en : ''}
                        error={inputErrors && inputErrors.name_en ? true : false}
                        InputLabelProps={{ shrink: true }}
                        // InputProps={{ disableUnderline: true }}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        variant="standard"
                        name='merchantNameCn'
                        placeholder={t('merchant.merchantNameCn') + '*'}
                        value={state?.name?.cn || ''}
                        onChange={handleChange}
                        helperText={inputErrors && inputErrors.name_cn ? inputErrors.name_cn : ''}
                        error={inputErrors && inputErrors.name_cn ? true : false}
                        InputLabelProps={{ shrink: true }}
                        // InputProps={{ disableUnderline: true }}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        variant="standard"
                        name='email'
                        placeholder={t('merchant.email') + '*'}
                        value={state?.email || ''}
                        onChange={handleChange}
                        helperText={inputErrors && inputErrors.email ? inputErrors.email : ''}
                        error={inputErrors && inputErrors.email ? true : false}
                        InputLabelProps={{ shrink: true }}
                        // InputProps={{ disableUnderline: true }}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl variant="outlined" fullWidth error={inputErrors && inputErrors['mobile_country'] ? true : false}>
                        <Select
                            value={state?.phone_country || ''}
                            label={t(`user.country`)}
                            name="phone_country"
                            onChange={handleChange}
                            displayEmpty
                            IconComponent={(props) => (
                                loadCountry
                                    ? <Autorenew {...props} className={styles.rotatingIcon} style={{ fontSize: 'xx-large' }} />
                                    : <KeyboardArrowDown {...props} style={{ fontSize: 'xx-large' }} />
                            )}
                            renderValue={(selected) => {
                                let countryName = _.find(mobileCodeList.country, {code: selected});
                                if (!selected) {
                                    return <p style={{ textAlign: 'left', color: '#2E3133' }}>{t('user.country')}</p>;
                                }
                                return <p style={{ textAlign: 'left', color: '#2E3133' }}>{i18n.language === 'cn' ? countryName?.name_cn : countryName?.name}</p>;
                            }}
                        >
                            <MenuItem key={0} value={0}>
                                --- {`${t('general.pleaseSelect')} ${t(`user.country`)} `} ---
                            </MenuItem>
                            {
                                _.map(mobileCodeList.country, (data, key) => {
                                    const countryName = i18n.language === 'cn' ? data.name_cn : data.name;
                                    return (
                                        <MenuItem key={key} value={data.code}>
                                            {countryName}
                                        </MenuItem>
                                    );
                                })
                            }
                        </Select>
                        {
                            inputErrors && inputErrors['mobile_country'] &&
                            <FormHelperText style={{ color: 'red' }}>{inputErrors['mobile_country']}</FormHelperText>
                        }
                        <Typography variant="caption" color="textSecondary" style={{ display: 'flex', textAlign: 'left' }}>{t('general.pleaseSelectCountry')}</Typography>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl ariant="outlined" fullWidth error={inputErrors && inputErrors['mobile_country_code'] ? true : false}>
                        <Select
                            name="phone_code"
                            label={t(`user.code`)}
                            value={state?.phone_code || ""}
                            onChange={handleChange}
                            displayEmpty
                            renderValue={(selected) => {
                                if (!selected) {
                                    return <p style={{ textAlign: 'left', color: '#2E3133' }}>{t('user.code')}</p>;
                                }
                                return <p style={{ textAlign: 'left', color: '#2E3133' }}>{selected}</p>;
                            }}
                        >
                            {_.size(mobileCodeList?.code) > 0 ? (
                                _.map(mobileCodeList?.code, (option, key) => (
                                    <MenuItem key={key} value={option} style={{ color: '#222' }}>
                                        {option}
                                    </MenuItem>
                                ))
                            ) : (
                                <MenuItem value="" style={{ color: '#222' }}>
                                    {t('general.pleaseSelect')}
                                </MenuItem>
                            )}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        variant="standard"
                        name='phone'
                        placeholder={t('merchant.phone') + '*'}
                        value={state?.phone || ''}
                        onChange={handleChange}
                        helperText={inputErrors && inputErrors.phone ? inputErrors.phone : ''}
                        error={inputErrors && inputErrors.phone ? true : false}
                        InputLabelProps={{ shrink: true }}
                        // InputProps={{ disableUnderline: true }}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        variant="standard"
                        name='code'
                        placeholder={t('merchant.merchantCode') + '*'}
                        value={state?.code || ''}
                        onChange={handleChange}
                        helperText={inputErrors && inputErrors.code ? inputErrors.code : t('merchant.noted')}
                        error={inputErrors && inputErrors.code ? true : false}
                        InputLabelProps={{ shrink: true }}
                        // InputProps={{ disableUnderline: true }}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        variant="standard"
                        name='companyRegisterNumber'
                        placeholder={t('merchant.companyRegisterNumber') + '*'}
                        value={state?.companyRegisterNumber || ''}
                        onChange={handleChange}
                        helperText={inputErrors && inputErrors.company_register_number ? inputErrors.company_register_number : ''}
                        error={inputErrors && inputErrors.company_register_number ? true : false}
                        InputLabelProps={{ shrink: true }}
                        // InputProps={{ disableUnderline: true }}
                        required
                        fullWidth
                    />
                </Grid>
            </Grid>
        </>
    )
}

const useStyles = makeStyles(theme => ({
    textFieldBox: {
        marginBottom: 20
    },
    '@keyframes infiniteRotate': {
        from: {
            transform: 'rotate(0deg)',
        },
        to: {
            transform: 'rotate(360deg)',
        },
    },
    rotatingIcon: {
        animation: '$infiniteRotate 2s linear infinite',
    },
}));